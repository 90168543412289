import React from "react";
import { SlashIcon, ArrowRegIcon } from "../../images/icons";
import { LinkIcon } from "../shared/links";
import { responsiveTextBasic, responsiveIconsBasic } from "../shared/styles";

export const WorkInfoNavbar = ({ isWork, setIsWork, artist }) => {
  return (
    <div
      className={`flex items-center ${responsiveTextBasic} mb-xl z-[29] bg-yellow-50 w-full`}
    >
      <div className={`flex items-center capitalize opacity-50 mr-5`}>
        <div className=" mr-5">
          {artist.firstName} {artist.lastName}
        </div>
        <ArrowRegIcon classes={`${responsiveIconsBasic}`} />
      </div>
      {!isWork && isWork != null ? (
        <button className={" "} onClick={() => setIsWork(true)}>
          <LinkIcon classes={responsiveTextBasic}> Work </LinkIcon>
        </button>
      ) : (
        <div className="opacity-50 ">Work</div>
      )}
      {/* > */}
      <div className="mx-5 flex items-center">
        <SlashIcon classes={`${responsiveIconsBasic}`} />
      </div>
      {isWork || isWork === null ? (
        <button className={""} onClick={() => setIsWork(false)}>
          <LinkIcon classes={responsiveTextBasic}> Info </LinkIcon>
        </button>
      ) : (
        <div className="opacity-50 ">Info</div>
      )}
    </div>
  );
};
