// This is the template for a single sign
import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/shared/layout";
import { Seo } from "../components/shared/seo";
import { WorkInfoNavbar } from "../components/artists/workInfoNavbar";
import { WorkGridItem } from "../components/shared/workGridItem";
import { gridStyle } from "../utils/gridStyles";
import { BackBtn } from "../components/shared/backBtn";
import { LinkIcon } from "../images/icons";
import { stickyNav } from "../components/shared/styles";
// ------------> Sign (single) page <------------ //
const ArtistPageTemplate = (props) => {
  // --->  Data <--- //
  const artist = props.data.strapiArtist;
  const { works, slug, born, websiteLink, livesAndWorks } =
    props.data.strapiArtist;
  // --->  NEXT PREV <--- //
  // const nextArtist = props.pageContext.nextArtistSlug;
  // const prevSign = props.pageContext.previousSignSlug;

  // ---> WORK / INFO <--- //
  const [isWork, setIsWork] = React.useState(null);

  return (
    <Layout location={props.location}>
      {/* NAVBAR */}
      <div className={`flex capitalize ${stickyNav}`}>
        <WorkInfoNavbar setIsWork={setIsWork} isWork={isWork} artist={artist} />
      </div>
      <div className={`${isWork | (isWork === null) ? gridStyle : ""}`}>
        {/* IS WORK? */}
        {isWork | (isWork === null)
          ? works.map((work, index) => {
              return (
                <WorkGridItem
                  work={work}
                  key={index}
                  slug={slug}
                  artist={artist}
                  classes={""}
                  isMedium={true}
                  location={props.location}
                />
              );
            })
          : // IS INFO?
            !isWork && (
              <>
                <div className="text-sm">Born:</div>
                <div>{born.place.country}</div>
                <div className="text-sm mt-md">Lives and works:</div>
                <div>
                  {livesAndWorks.place.map((placeItem, index) => {
                    return <div key={index}>{placeItem.country}</div>;
                  })}
                </div>
                <a
                  href={`${websiteLink}`}
                  target="_blank"
                  className=""
                  rel="noreferrer"
                >
                  <div className="flex items-center mt-md">
                    <LinkIcon classes={`h-[1rem] mr-sm `} />
                    <div>Website link</div>
                  </div>
                </a>
              </>
            )}
      </div>
      {/* ---> BACK BTN <--- */}
      <BackBtn classes={"my-xl"} />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    site {
      siteMetadata {
        title
      }
    }
    strapiArtist(slug: { eq: $slug }) {
      firstName
      lastName
      born {
        place {
          city
          country
        }
      }
      slug
      websiteLink
      livesAndWorks {
        place {
          country
          city
        }
      }
      works {
        artist {
          slug
          firstName
          lastName
        }
        id
        year
        title
        mediums {
          id
          mediumType
        }
        heroImage {
          imageFile {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: NO_CHANGE
                  width: 700
                  #height: 100
                  quality: 100
                  #layout: FULL_WIDTH
                  transformOptions: { fit: COVER }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default ArtistPageTemplate;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Artists"}
    description={"IlCollection Artists"}
    keywords={"IlCollection Artists, Art"}
  />
);
